
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import React, { useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useIntersection } from 'react-use';
import classes from './index.module.scss';
import { StatisticData } from './types';
import { intlNumberFormatter } from '@/helpers/intlNumberFormatter';
const Stats: React.FunctionComponent<{
    statistics: StatisticData[];
}> = ({ statistics, }) => {
    const { t, lang } = useTranslation('home');
    const ref = useRef<HTMLDivElement | null>(null);
    const [wasInView, setWasInView] = useState(false);
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
    });
    if (!wasInView && intersection && intersection.isIntersecting) {
        setWasInView(true);
    }
    return (<div ref={ref} className={classes.statsGrid}>
      {statistics.map((statistic, index) => (<div key={index}>
          <div className={classes.statsBoxTitle}>
            <CountUp start={0} decimals={statistic.value < 10 ? 1 : 0} formattingFn={(value: number): string => {
                return statistic.getFormattedValue(intlNumberFormatter(value, lang));
            }} end={wasInView ? statistic.value : 0} duration={1}/>
          </div>
          <div className={classes.statsBoxDescription}>
            {t(statistic.description, statistic.descriptionParams && { ...statistic.descriptionParams })}
          </div>
        </div>))}
    </div>);
};
export default Stats;

    async function __Next_Translate__getStaticProps__1910ce3e2e3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/Stats/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce3e2e3__ as getStaticProps }
  